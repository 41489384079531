<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-19 09:35:21
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-10 19:36:14
-->
<template>
  <div>
    <div class="item">
      <div class="head">{{item.contractName}}</div>
      <div class="subhead">
        <el-button round type="primary" size="mini" class="btn_sm">签约</el-button>
        {{item.ocompanyName}}
      </div>
      <div class="subhead">
        <el-button round type="warning" size="mini" class="btn_sm">服务</el-button>
        {{item.ecompanyName}}
      </div>
      <div
        class="text"
        v-if="item.status != 'unifiedSignSuccess' && item.status != 'unifiedSignFailure' "
      >
        协议日期：{{item.contractBeginDate}}
        <span v-if="item.contractBeginDate!=null">~</span>
        {{item.contractEndDate}}
        <!-- <el-button type="warning" size="mini" class="btn_sm">续签</el-button> -->
      </div>
      <div class="text">
        <span v-if="item.bankName!=null && item.bankName=='支付宝'">
          支付宝：({{item.bankAccount}})
        </span>
        <span v-if="item.bankName!=null && item.bankName !='支付宝'">
          银行卡：
          {{item.bankName}}({{item.bankAccount}})
        </span>
        <!--银行卡：-->
        <!--<span v-if="item.bankName">{{item.bankName}}({{item.bankAccount}})</span>-->
        <el-button
          type="primary"
          size="mini"
          class="btn_sm"
          @click="handleChangeCard"
          v-if="item.status == 'signed' || item.status == 'unifiedSignSuccess' || item.status == 'unifiedSignFailure'"
        >更换</el-button>
      </div>
      <template
        v-if="item.status == 'signed' || item.status == 'unifiedSignSuccess' || item.status == 'unifiedSignFailure'"
      >
        <span class="icon_type type_did"></span>
        <el-button
          type="warning"
          class="btn_block"
          v-if="item.status == 'signed'"
          @click="check(item)"
        >查看协议</el-button>
      </template>
      <template v-if="item.status == 'termination'||item.status == 'unifiedSignSuccess'">
        <span class="icon_type type_end"></span>
        <el-button type="warning" class="btn_block" @click="check(item)">查看协议</el-button>
      </template>
      <template v-if="item.status == 'wsign'">
        <span class="icon_type type_no"></span>
        <el-button type="primary" class="btn_block" @click="signContract(item)">发起电签</el-button>
      </template>
      <span class="icon_type type_lose" v-if="item.status == 'cancelled'"></span>
      <span class="icon_type type_all" v-if="item.status == 'unifiedSignSuccess'"></span>
    </div>
    <el-dialog title="更换收款帐号" :visible.sync="isShowBank" width="500px">
      <!-- <cardItem
        v-for="(item1,index) in cardList"
        :key="'card'+index"
        :item="item1"
        class="bank_card"
        :class="{active:item1.secrecyAccount == item.bankAccount}"
        @click.native="handleCardSelect(item1)"
      ></cardItem>
      <alipayItem
      v-for="(item1,index) in alipayList"
      :key="'card'+index"
      :item="item1"
      class="bank_card"
      :class="{active:item1.secrecyAccount == item.bankAccount}"
              @click.native="handleCardSelect(item1)"
        ></alipayItem> -->

        <bank-card @setCardNumber="cardListLength = $event" :addBankVisible='addBankVisible' :untieButton='false' @setVisible="addBankVisible = $event" :selectInfo="item" @selected="handleCardSelect($event)" />
        <!--<alipay-card @setAlipayNumber="alipayLength = $event" :addAlipayVisible='addAlipayVisible' @setVisible="addAlipayVisible = $event"  :selectInfo="item" @selected="handleCardSelect($event)"/>-->
        <!---->
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <div class="dialog">
        <span class="dialog-text">请先用微信扫描下方二维码，关注【工蚁宝】微信公众号完成个体户认证与注册作</span>
        <el-image 
        class="dialog-img"
        :src="url">
      </el-image>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/list";
import {
  itemStatusHttpAction,
} from "@/api/status";
import bankCard from "./bankCard.vue";
import alipayCard from "./alipayCard.vue";
export default {
  components: {
    bankCard,
    alipayCard,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      isShowBank: false, 
      cardListLength: 0,
      addBankVisible: false,
      addAlipayVisible: false,
      alipayLength: 0,
      dialogVisible:false,
      url: require('@/assets/header_qrcode.jpg')
    };
  },
  methods: {
    handleCardSelect(item) {

      // 如果是支付宝就进行校验操作
      if (item._type === 2) {
        // 判断人力公司是否支持支付宝付款方式
        api.checkSupportAliPay({ocompanyId: item.ocompanyId}, {method: "get"}).then((res) => {
          if (!res.data) {
            this.$message.error('暂不支持支付宝收款，请选择储蓄卡！');
            return;
          }
          this.handleCardSelectAfterCheck(item);
        });
      } else {
        this.handleCardSelectAfterCheck(item);
      }

    },
    handleCardSelectAfterCheck(item){
      this.item.bankAccount = item.secrecyAccount;
      this.item.bankName = item._bankName;

      api
              .bank(
                      {
                        contractId: this.item.id,
                        name: this.item.bankName,
                        bankNo: item._bankAccount,
                      },
                      { method: "post" }
              )
              .then((res) => {
                if (res.status == "200") {
                  this.$message({
                    message: "更换成功",
                    type: "success",
                  });
                  this.isShowBank = false;
                  this.$emit("getList")
                } else {
                  this.$message.error(res.message);
                }
              });
    },
    handleChangeCard() {
      this.isShowBank = true; 
    },
    check(item) {
      let routeData = null;
      if (!item.contractFile) {
        api.contractUrl({ id: item.id }).then((res) => {
          if (res.status == "200") {
            routeData = this.$router.resolve({
              path: "/pdfPreview",
              query: {
                fileURL: process.env.VUE_APP_FILE_PATH + res.data.data,
              },
            });
          }
        });
      } else {
        routeData = this.$router.resolve({
          path: "/pdfPreview",
          query: {
            fileURL: process.env.VUE_APP_FILE_PATH + item.contractFile,
          },
        });
      }
      window.open(routeData.href, "_blank");
    },
    signContract(item) {
      console.log("item",item)
      api.getRelationIndividual({ id: item.ocompanyId }, { method: "get" })
      .then((res) => {
        console.log('res',res)
        if (res.status == 200) {
          if(!res.data){
            // 自由职业者人力公司，跳转至灵工签署页面
            this.$router.push({path:'./signContract',query: {contractId: item.id, ocompanyId: item.ocompanyId}})
          }else{
            // 个体户人力公司，跳转至个体户签署页面，跳转至个体户签署页面时，需要判断当前关联的个体户是否注册成功
            itemStatusHttpAction(item.ocompanyId).then((res) => {
              if (res.status == "200"&&res.data.flag) {
                // 成功：跳转至个体户签署页面
                this.$router.push({path:'./signContractIndividual',query: {contractId: item.id,ocompanyId: item.ocompanyId}})
              }else {
                if (res.data.type == 1) {
                  // 提示去微信认证
                  this.dialogVisible = true;
                }else if (res.data.type == 3) {
                  this.$message.error("个体户已注销，不支持签约！");
                } else {
                  this.$message.error("个体户正在注销中，暂不支持签约！");
                }
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bank_card {
  position: relative;
  &.active {
    top: -2px;
    &:after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 55px;
      height: 14px;
      background: url("../../../assets/selected.png") center center no-repeat;
    }
  }
}
.item {
  position: relative;
  display: block;
  border: 1px dashed #eee;
  border-radius: 12px;
  margin-bottom: 12px;
  background: #fff;
  padding: 12px 20px 20px;
  font-size: 12px;
  color: #666;
  line-height: 26px;
  .head {
    color: #333;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .subhead {
    margin-bottom: 10px;
    .btn_sm {
      float: none;
      margin-left: 0;
    }
  }

  .btn_block {
    display: block;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .btn_sm {
    display: inline-block;
    padding: 4px 6px;
    margin-left: 12px;
  }
  .icon_type {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 46px;
    height: 46px;
    margin-top: -24px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    &.type_did {
      background-image: url(./assets/type_ok.png);
    }
    &.type_no {
      background-image: url(./assets/type_no.png);
    }
    &.type_lose {
      background-image: url(./assets/type_ineff.png);
    }
    &.type_end {
      background-image: url(./assets/type_end.png);
    }
    &.type_all {
      background-image: url(./assets/type_all.png);
    }
  }
}
.dialog{
  text-align: center;
  .dialog-text{
    display: block;
    color: #8493FA;
  }
  .dialog-img{
    max-width: 300px; 
    max-height: 300px
  }
}
</style>