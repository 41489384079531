<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-01 18:34:07
-->
<template>
  <el-card header="我的协议" class="box-card" shadow="never" style="min-height:750px">
    <template v-if="list.length">
      <contract-item v-for="(item,index) in list" :key="'contract'+index" :item="item" @getList="getList"></contract-item>
      <pagination
        :listLoading="listLoading"
        @getList="getList"
        @setLimit="searchForm.size = $event"
        @setPage="searchForm.current = $event"
        :currentPage="searchForm.current"
        :pageSize="searchForm.size"
        :total="searchForm.total"
      ></pagination>
    </template>
    <template v-else>
      <empty text="暂无协议"></empty>
    </template>
  </el-card>
</template>

<script>
import empty from "@/components/empty/index.vue";
import contractItem from "./components/contractItem.vue";
import pagination from "@/components/pagination/index.vue";
import api from "@/api/list";
export default {
  components: {
    contractItem,
    pagination,
    empty,
  },
  data() {
    return {
      list: [],
      listLoading: false,
      searchForm: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      api.contractListByActiveUser(this.searchForm).then((res) => {
        if (res.status == "200") {
          this.listLoading = false;
          this.list = res.data.records;
          this.searchForm.total = res.data.total;
        } else {
          console.error(res.message);
        }
      });
    },
  },
};
</script>